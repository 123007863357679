import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {
  Grid,
  NativeSelect,
  FormControl,
  Divider,
  Radio,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
  TextareaAutosize,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Alert from '@material-ui/lab/Alert';
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import StyledLink from "../common/StyledLink";
import {
  addBasicInfo,
  increaseStep,
  fetchProductList,
  fetchSimDetails,
  reset,
  saveCurrentSimProductType
} from "../../redux/sims/actions";
import TagsInput from 'react-tagsinput-special';
import { sfReset } from "../../redux/sf/actions";
import { useBasicInfoStyles, BootstrapInput } from "./BasicInfo";
import { removePdf, addCaptivate, addTutorial, addCaptivateTutorial, removeUploads } from "../../services/result.service";
import LinearProgress from '@material-ui/core/LinearProgress';

const useQuery = () => new URLSearchParams(useLocation().search);

const BasicInfo = ({
  newSim,
  addBasicInfo,
  increaseStep,
  fetchProductList,
  products,
  fetchSimDetails,
  reset,
  sfReset,
  saveCurrentSimProductType
}) => {
  const query = useQuery();
  const classes = useBasicInfoStyles();
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [uploadInProgress, setUploadInProgress] = React.useState(false);
  const [links, setLinks] = React.useState([]);
  const [linearLoader, setLinearLoader] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setSeverity] = useState('');
  const [alertMesage, setAlertMessage] = useState('');
  const [linearTutorialLoader, setLinearTutorialLoader] = useState(false);
  const [linearCaptivateTutorial, setLinearCaptivateTutorialLoader] = useState(false);
  const callTypes=["Physician Referral",
                   "Appointment",
                   "General Information",
                   "Insurance",
                   "My CSLink",
                   "Center Referral"];
  useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);
  const [data, setData] = useState({
    ...newSim,
    createdOn: moment().format("MMM DD YYYY"),
  });
  const [state, setState] = useState({
    title: newSim.title,
    description: newSim.description,
    brief_description: newSim.brief_description,
    product_id: newSim.product_id,
    sim_type: newSim.sim_type,
    auto_populate: newSim.auto_populate,
    real_time_feedback: newSim.real_time_feedback,
    admin_note: newSim.admin_note,
    draft: newSim.draft,
    call_type: newSim.call_type,
    natural_lenguage: newSim.natural_lenguage,
  });
  useEffect(() => {
    if (query.get("simId")) {
      fetchSimDetails(query.get("simId"));
    } else if (newSim.id) {
      fetchSimDetails(newSim.id);
    } else if (!data.id) {
      reset();
      sfReset();
    }
  }, [fetchSimDetails, reset]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      title: newSim.title,
      description: newSim.description,
      brief_description: newSim.brief_description,
      product_id: newSim.product_id,
      sim_type: newSim.sim_type,
      auto_populate: newSim.auto_populate,
      real_time_feedback: newSim.real_time_feedback,
      admin_note: newSim.admin_note,
      draft: newSim.draft,
      call_type: newSim.call_type,
      natural_lenguage: newSim.natural_lenguage,
    }));
    if (newSim?.documents?.length) {
      setSelectedFile(newSim.documents);
    }
    if (newSim?.hot_links?.length) {
      setLinks(newSim.hot_links);
    }
    if(newSim.captivate_folder_name) {
      setState((prev) => ({
        ...prev,
        captivate_folder_name: newSim.captivate_folder_name,
      }));
    }
    if(newSim.tutorial_video_name) {
      setState((prev) => ({
        ...prev,
        tutorial_video_name: newSim.tutorial_video_name,
      }));
    }
    if(newSim.captivate_learning_video_name) {
      setState((prev) => ({
        ...prev,
        captivate_demo_video: newSim.captivate_learning_video_name,
      }));
    }
    setData(newSim);
    if(newSim.id && !localStorage.getItem("sims_id")) {
      localStorage.setItem('sims_id', newSim.id);
    }
  }, [newSim, products]);
  const updateData = (characterData) => {
    setData((prevData) => ({ ...prevData, ...characterData }));
  };
  
  const handleChange = async (evt) => {
    const { value } = evt.target;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const handleSelectBoxChange = async (evt) => {
    const { value } = evt.target;
    const requestData = { ...state };
    if (evt.target.name === 'product_id') {
      requestData.product_id = value;
      for (const product of products) {
        if (product._id === value) {
          requestData.sim_type = product.name ==="Hiring SIM" ? "hiring" : "training";
          saveCurrentSimProductType(product.name);
        }
      }
    } else {
      requestData.call_type = value;
    }
    
    setState(requestData);
    if (data.id) {
      const formData = new FormData();
      requestData._id = data.id;
      const details = JSON.stringify(requestData);
      formData.append("data", details);
      await addBasicInfo(formData, requestData);
    }
    updateData(requestData);
  }

  const validate = async (evt) => {
    if (state.title) {
      const res = { selectSimType: {} };
      res.title = state.title;
      res.description = state.description;
      res.brief_description = state.brief_description;
      res.product_id = state.product_id;
      res.sim_type = state.sim_type;
      res.auto_populate = state.auto_populate;
      res.real_time_feedback = state.real_time_feedback;
      res.natural_lenguage = state.natural_lenguage;
      res.draft = state.draft;
      res.admin_note = state.admin_note;
      res.call_type = state.call_type;
      updateData(res);
      if (evt.target.name === 'title' || evt.target.name === 'description' || evt.target.name === 'brief_description' ||
        evt.target.name === 'admin_note') {
        const { value } = evt.target;
        const requestData = {};
        const formData = new FormData();
        if (data.id) {
          requestData._id = data.id;
        }
        const currentState = { ...state, [evt.target.name]: value };
        requestData.title = currentState.title;
        requestData.description = currentState.description;
        requestData.brief_description = currentState.brief_description;
        requestData.admin_note = currentState.admin_note;
        requestData.product_id = currentState.product_id ? currentState.product_id : products[0]._id;
        requestData.call_type = currentState.call_type ? currentState.call_type : callTypes[0];
        const details = JSON.stringify(requestData);
        formData.append("data", details);
        await addBasicInfo(formData, requestData);
      }
    }
  };

  const handleCheckboxChange = async (evt) => {
    setState({ ...state, [evt.target.name]: evt.target.checked });
    if (data.id) {
      const requestData = {};
      const formData = new FormData();
      requestData._id = data.id;
      const currentState = { ...state, [evt.target.name]: evt.target.checked };
      requestData.auto_populate = currentState.auto_populate;
      requestData.real_time_feedback = currentState.real_time_feedback;
      requestData.natural_lenguage = currentState.natural_lenguage;
      requestData.draft = currentState.draft;
      const details = JSON.stringify(requestData);
      formData.append("data", details);
      await addBasicInfo(formData, requestData);
    }
  };

  const handleNext = async () => {
    setUploadInProgress(true);
    const requestData = {};
    const formData = new FormData();
    if (data.id) {
      requestData._id = data.id;
    }
    requestData.title = data.title;
    requestData.description = data.description;
    requestData.brief_description = data.brief_description;
    requestData.sim_type = data.sim_type;
    requestData.auto_populate = data.auto_populate;
    requestData.real_time_feedback = data.real_time_feedback;
    requestData.natural_lenguage = data.natural_lenguage;
    requestData.draft = data.draft;
    requestData.product_id =
      state.product_id === "" ? products[0]._id : state.product_id;
    requestData.admin_note = state.admin_note;
   // requestData.hot_links =  links;
    const details = JSON.stringify(requestData);
    formData.append("data", details);
    await addBasicInfo(formData, requestData);
    setUploadInProgress(false);
    increaseStep();
  };
  const fileSelected = async (evt) => {
    const docs = selectedFile.concat(evt.target.files[0]);
    setSelectedFile(docs);
    if (data.id) {
      if (newSim?.documents?.length) {
        newSim.documents = docs;
      }
      const requestData = {};
      requestData._id = data.id;
      const formData = new FormData();
      formData.append('file', evt.target.files[0]);
      const details = JSON.stringify(requestData);
      formData.append("data", details);
      await addBasicInfo(formData, requestData);
    }
  };
  const removeDocument = async (file) => {
    const array = [...selectedFile]; // make a separate copy of the array
    const index = file._id
      ? selectedFile.findIndex((sf) => sf._id === file._id)
      : selectedFile.findIndex((sf) => sf.name === file.name);

    if (index !== -1) {
      array.splice(index, 1);
      setSelectedFile(array);
      if(data.id) {
        newSim.documents = array
      }
      if (file._id) {
        await removePdf(data.id, file._id);
      }
    }
  };
  const removeUploadsMethod = async (fieldName) => {
    setState({
      ...state,
      [fieldName]: '',
    });
    if (data.id) {
      await removeUploads(data.id, fieldName);
    }
  };
  const linkChange = async (e) => {
    setLinks(e);
    if (data.id) {
      const requestData = {};
      requestData._id = data.id;
      requestData.hot_links = e;
      const formData = new FormData();
      const details = JSON.stringify(requestData);
      formData.append("data", details);
      await addBasicInfo(formData, requestData);
    }
  };
  const selectCaptivateFolder = async(evt) => {
    const formData = new FormData();
    formData.append('folder', evt.target.files[0]);
    setState({
      ...state,
      [evt.target.name]: evt.target.files[0].name,
    });
    setLinearLoader(true);
    await addCaptivate(formData, data.id).then((res) => {
      if (res.success) {
        setSeverity('success');
        setLinearLoader(false);
        setOpenAlert(true);
        setAlertMessage('Captivate Learning Uploaded successfully !');
      } else {
        setSeverity('error');
        setLinearLoader(false);
        setOpenAlert(true);
        setAlertMessage('There is an error in Uploading Captivate Learning!');
      }
    });
  };
  const uploadTutorial = async (evt) => {
    if (evt.target.files[0].size / 1000000 > 100) {
      setOpenAlert(true);
      setSeverity('warning');
      setAlertMessage('Video size exceeds more than 100mb');
    } else {
      const formData = new FormData();
      formData.append("video_blob", evt.target.files[0]);
      setState({
        ...state,
        [evt.target.name]: evt.target.files[0].name,
      });
      setLinearTutorialLoader(true);
      await addTutorial(formData, data.id).then((res) => {
        if (res.success) {
          setSeverity("success");
          setLinearTutorialLoader(false);
          setOpenAlert(true);
          setAlertMessage("Tutorial Uploaded successfully !");
        } else {
          setSeverity("error");
          setLinearTutorialLoader(false);
          setOpenAlert(true);
          setAlertMessage("There is an error in Uploading Tutorial!");
        }
      });
    }
  };
  const captivateDemoVideo = async (evt) => {
    if (evt.target.files[0].size / 1000000 > 100) {
      setOpenAlert(true);
      setSeverity('warning');
      setAlertMessage('Video size exceeds more than 100mb');
    } else {
      const formData = new FormData();
      formData.append("video_blob", evt.target.files[0]);
      setState({
        ...state,
        [evt.target.name]: evt.target.files[0].name,
      });
      setLinearCaptivateTutorialLoader(true);
      await addCaptivateTutorial(formData, data.id).then((res) => {
        if (res.success) {
          setSeverity("success");
          setLinearCaptivateTutorialLoader(false);
          setOpenAlert(true);
          setAlertMessage("Captivate Tutorial Uploaded successfully !");
        } else {
          setSeverity("error");
          setLinearCaptivateTutorialLoader(false);
          setOpenAlert(true);
          setAlertMessage("There is an error in Uploading Captivate Tutorial!");
        }
      });
    }
  };
  useEffect(() => {
    if (openAlert) {
      setTimeout(() => {
        setOpenAlert(false);
      }, 3000);
    }
  }, [openAlert]);
  return (
    <Paper elevation={0}>
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Basic Information
        </Typography>
        {openAlert ? (
        <Alert
          variant="filled"
          severity={alertSeverity}
          className={alertSeverity === 'error' ? classes.errorAlert : ''}
        >
          {alertMesage}
        </Alert>
      ) : null}
        <StyledLink to="/sims">
          <Button
            variant="contained"
            className={classes.cancel}
            disableElevation
          >
            CANCEL
          </Button>
        </StyledLink>
        <Button
          variant="contained"
          color="primary"
          disabled={!(state.title && state.description)}
          className={classes.nextButton}
          disableElevation
          onClick={handleNext}
        >
          {uploadInProgress && selectedFile.length ? (
            <CircularProgress color="#FFFFFF" size={25} />
          ) : (
            "NEXT"
          )}
        </Button>
      </Toolbar>
      <Divider />

      <form className={classes.root}>
        <div>
          <Grid className={classes.formHeight} container>
            <Grid className={classes.colBorderRight} item xs={6}>
              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                  Title
                </InputLabel>
                <TextField
                  required
                  variant="outlined"
                  name="title"
                  size="small"
                  error={!state.title}
                  fullWidth
                  onBlur={validate}
                  value={state.title}
                  className={classes.margin}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                  Description
                </InputLabel>
                <TextareaAutosize
                  required
                  minRows={8}
                  name="description"
                  value={state.description}
                  onBlur={validate}
                  className={!state.description ? classes.error: classes.description}
                  onChange={handleChange}
                  data-enable-grammarly="false"
                />
              </div>

              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                  Brief Description
                </InputLabel>
                <TextareaAutosize
                  minRows={16}
                  name="brief_description"
                  value={state.brief_description}
                  onBlur={validate}
                  className={classes.description}
                  onChange={handleChange}
                  data-enable-grammarly="false"
                />
              </div>
              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                  Admin Notes
                </InputLabel>
                <TextareaAutosize
                  required
                  minRows={8}
                  name="admin_note"
                  value={state.admin_note}
                  onBlur={validate}
                  className={classes.description}
                  onChange={handleChange}
                  data-enable-grammarly="false"
                />
              </div>
            </Grid>
            <Grid className={classes.colBorderLeft} item xs={6}>
              <div className={classes.paper}>
                <Grid container>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="product" className={classes.inputLable}>
                      Product
                    </InputLabel>
                    <NativeSelect
                      className={classes.select}
                      name="product_id"
                      value={state.product_id}
                      onChange={(evt) => handleSelectBoxChange(evt)}
                      variant="outlined"
                      required
                      input={<BootstrapInput />}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      {Object.entries(products).map(([key, product]) => (
                        <option name={product.name} value={product._id} key={key}>
                          {product.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="product" className={classes.inputLable}>
                      Call Types
                    </InputLabel>
                    <NativeSelect
                      className={classes.select}
                      name="call_type"
                      value={state.call_type}
                      onChange={(evt) => handleSelectBoxChange(evt)}
                      variant="outlined"
                      required
                      input={<BootstrapInput />}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      {Object.entries(callTypes).map(([key, callType]) => (
                        <option value={callType} key={key}>
                          {callType}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.paper}>
                <InputLabel htmlFor="sim-type" className={classes.inputLable}>
                  Select Sim Type
                </InputLabel>
                <Radio
                  className={classes.radio}
                  checked={state.sim_type === "hiring"}
                  name="sim_type"
                  value="hiring"
                  onBlur={validate}
                  color="primary"
                  inputProps={{ "aria-label": "Hiring" }}
                />
                Hiring (One Level sim)
                <Radio
                  checked={state.sim_type !== "hiring"}
                  value="training"
                  color="primary"
                  onBlur={validate}
                  name="sim_type"
                  inputProps={{ "aria-label": "Training" }}
                />
                Training (Multi Level sim)
              </div>
              <div className={classes.paper}>
                <div className={classes.border}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={state.auto_populate}
                            checked={state.auto_populate}
                            onBlur={validate}
                            name="auto_populate"
                            onChange={handleCheckboxChange}
                            style={{ color: "#CED4DA" }}
                          />
                        }
                        label="Enable auto-populate"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="real-time-feedback"
                            name="real_time_feedback"
                            checked={state.real_time_feedback}
                            onBlur={validate}
                            onChange={handleCheckboxChange}
                            style={{ color: "#CED4DA" }}
                          />
                        }
                        label="Enable Real-time feedback"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="natural-lenguage"
                            name="natural_lenguage"
                            checked={state.natural_lenguage}
                            onBlur={validate}
                            onChange={handleCheckboxChange}
                            style={{ color: "#CED4DA" }}
                          />
                        }
                        label="Enable Natural Language AI"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
              <div className={classes.paper}>
                <div className={classes.border}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="draft"
                            name="draft"
                            checked={state.draft}
                            onBlur={validate}
                            onChange={handleCheckboxChange}
                            style={{ color: "#CED4DA" }}
                          />
                        }
                        label="Draft"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
              <div className={classes.paper}>
                <Grid container>
                  <Grid item xs={6} className={classes.colBorderRight}>
                    <InputLabel className={classes.inputLable}>Add attachment</InputLabel>
                    <Grid container>
                      <Grid item xs={4}>
                        <Tooltip
                          placement="top"
                          title={
                            selectedFile.length === 5
                              ? "Only 5 documents can be attached at a time."
                              : ""
                          }
                        >
                          <span>
                            <Button
                              color="primary"
                              className={
                                selectedFile.length === 5
                                  ? classes.browseDisableBtn
                                  : classes.browseBtn
                              }
                              component="label"
                              variant="outlined"
                            >
                              Browse{" "}
                              <input
                                hidden
                                type="file"
                                accept=".pdf"
                                onChange={fileSelected}
                              />
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={8} className={classes.fileName}>
                        {selectedFile &&
                          selectedFile.map((file, index) => (
                            <div key={index}>
                              <Tooltip title={file && file.name.length > 25 ? file.name : ''}>
                                <span className={classes.docs}>
                                  { file.name > 25
                                    ? `${file.name.slice(0, 25)} ...` : file.name}
                                    <CloseIcon
                                      onClick={() => removeDocument(file)}
                                      className={classes.closeIcon}
                                      fontSize="small"
                                    />
                                </span>
                              </Tooltip>
                              <br />
                            </div>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} className={classes.colBorderLeft}>
                    <InputLabel htmlFor="title" className={classes.inputLable} style={{marginBottom: 10}}>
                      Add Tutorial Video
                    </InputLabel>
                    {linearTutorialLoader ? <LinearProgress color="primary" className={classes.linear}/> : null}
                    <Button
                      color="primary"
                      component="label"
                      variant="outlined"
                      className={classes.linear}
                    >
                      Browse
                      {' '}
                      <input
                        hidden
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={uploadTutorial}
                        name="tutorial_video_name"
                      />
                    </Button>
                    <Tooltip title={state && state.tutorial_video_name && state.tutorial_video_name.length > 25 ? state.tutorial_video_name : ''}>
                      <span className={classes.captivatefilename}>
                        {state && state.tutorial_video_name && state.tutorial_video_name > 25
                          ? `${state.tutorial_video_name.slice(0, 25)} ...` : state.tutorial_video_name}
                          {
                            state && state.tutorial_video_name && !linearTutorialLoader &&
                              <CloseIcon
                              onClick={() => removeUploadsMethod('tutorial_video_name')}
                              className={classes.closeIcon}
                              fontSize="small"
                            />
                          }
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.paper}>
                <Grid container>
                  <Grid item xs={6} className={classes.colBorderRight}>
                      <InputLabel htmlFor="title" className={classes.inputLable} style={{marginBottom: 10}}>
                        Select Captivate Folder
                      </InputLabel>
                      {linearLoader ? <LinearProgress color="primary" className={classes.linear}/> : null}
                      <Button
                        color="primary"
                        component="label"
                        variant="outlined"
                        className={classes.linear}
                      >
                        Browse
                        {' '}
                        <input
                          hidden
                          type="file"
                          accept=".zip,.rar,.7zip"
                          onChange={selectCaptivateFolder}
                          name="captivate_folder_name"
                        />
                      </Button>
                      <Tooltip title={state && state.captivate_folder_name && state.captivate_folder_name.length > 25 ? state.captivate_folder_name : ''}>
                          <span className={classes.captivatefilename}>
                            {state && state.captivate_folder_name && state.captivate_folder_name > 25
                              ? `${state.captivate_folder_name.slice(0, 25)} ...` : state.captivate_folder_name}
                            {
                            state && state.captivate_folder_name && !linearLoader &&
                              <CloseIcon
                              onClick={() => removeUploadsMethod('captivate_folder_name')}
                              className={classes.closeIcon}
                              fontSize="small"
                            />
                            }
                          </span>
                      </Tooltip>
                  </Grid>
                  <Grid item xs={6} className={classes.colBorderLeft}>
                      <InputLabel htmlFor="title" className={classes.inputLable} style={{marginBottom: 10}}>
                        Select Captivate Learning Demo Video
                      </InputLabel>
                      {linearCaptivateTutorial ? <LinearProgress color="primary" className={classes.linear}/> : null}
                      <Button
                        color="primary"
                        component="label"
                        variant="outlined"
                        className={classes.linear}
                      >
                        Browse
                        {' '}
                        <input
                          hidden
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          onChange={captivateDemoVideo}
                          name="captivate_demo_video"
                        />
                      </Button>
                      <Tooltip title={state && state.captivate_demo_video && state.captivate_demo_video.length > 25 ? state.captivate_demo_video : ''}>
                          <span className={classes.captivatefilename}>
                            {state && state.captivate_demo_video && state.captivate_demo_video > 25
                              ? `${state.captivate_demo_video.slice(0, 25)} ...` : state.captivate_demo_video}
                            {
                              state && state.captivate_demo_video && !linearCaptivateTutorial &&
                              <CloseIcon
                              onClick={() => removeUploadsMethod('captivate_demo_video')}
                              className={classes.closeIcon}
                              fontSize="small"
                            />
                            }
                          </span>
                      </Tooltip>
                  </Grid>
                </Grid>
              </div>
             
              <div className={classes.paper}>
                <InputLabel htmlFor="title" className={classes.inputLable}>
                Add URL link(s)
                </InputLabel>
                <TagsInput
                    onlyUnique
                    value={links}
                    className={classes.tagInput}
                    name="link"
                  //  onFocusOut={(e) => hotLinkSave(e)}
                    onChange={linkChange}
                    inputProps={{
                      style: { width: '400px' },
                    }}
                  />
                  <em className={classes.toolBarValue}>
                    Click on &apos;Enter&apos; button after adding each link.
                  </em>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </Paper>
  );
};

BasicInfo.propTypes = {
  newSim: PropTypes.object,
  addBasicInfo: PropTypes.func,
  increaseStep: PropTypes.func,
  fetchProductList: PropTypes.func,
  products: PropTypes.array,
  fetchSimDetails: PropTypes.func,
  reset: PropTypes.func,
  sfReset: PropTypes.func,
  saveCurrentSimProductType: PropTypes.func
};
const mapStateToProps = (state) => ({
  newSim: state.sim.newSim,
  products: state.sim.products,
});

const mapDispatchToProps = {
  addBasicInfo,
  increaseStep,
  fetchProductList,
  fetchSimDetails,
  reset,
  sfReset,
  saveCurrentSimProductType
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
