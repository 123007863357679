// reference https://github.com/amazon-archives/amazon-transcribe-websocket-static
import MicrophoneStream from "microphone-stream";
import { EventStreamCodec } from "@aws-sdk/eventstream-codec";
//const marshaller = require("@aws-sdk/eventstream-marshaller");
const util_utf8_node = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
//const eventStreamMarshaller = new marshaller.EventStreamMarshaller(
const eventStreamMarshaller = new EventStreamCodec(
  util_utf8_node.toUtf8,
  util_utf8_node.fromUtf8
);
let partialTranscription = '';

export const pcmEncode = (input) => {
  let offset = 0,
      buffer = new ArrayBuffer(input.length * 2),
      view = new DataView(buffer);

  for (let i = 0; i < input.length; i++, offset += 2) {
    let s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }

  return buffer;
}

export const downsampleBuffer = (
  buffer,
  inputSampleRate = 44100,
  outputSampleRate = 16000
) => {
  if (outputSampleRate === inputSampleRate) {
    return buffer;
  }

  let sampleRateRatio = inputSampleRate / outputSampleRate,
    newLength = Math.round(buffer.length / sampleRateRatio),
    result = new Float32Array(newLength),
    offsetResult = 0,
    offsetBuffer = 0;

  while (offsetResult < result.length) {
    let nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio),
        accum = 0,
        count = 0;

    for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }

  return result;
}

/**
 * Produces an object suitable to be passed over a WebSocket Message event.
 *
 * @param buffer Buffer
 * @returns {{headers: {":event-type": {type: string, value: string}, ":message-type": {type: string, value: string}}, body}}
 */
export const getAudioEventMessage = (buffer) => {
  // wrap the audio data in a JSON envelope
  return {
    headers: {
      ":message-type": {
        type: "string",
        value: "event",
      },
      ":event-type": {
        type: "string",
        value: "AudioEvent",
      },
    },
    body: buffer,
  };
}

/**
 * Resets the partialTranscription variable used by handleEventStreamMessage to keep track of the partial
 * conversion of the speech to text engine.
 *
 * This has to be called either BEFORE starting a new audio recording to be sent to the speech to text engine or
 * AFTER the conversion has been completed and the transcript has been accepted/used.
 */
export const resetEventStreamMessage = () => {
  partialTranscription = '';
}

/**
 * Creates a string concatenating every result item returned by the speech to text engine. This string is
 * stored in a "temporary" (partialTranscription) variable while the response engine marks the contents
 * of the response as "partial". Once the response's contents are marked as "NOT partial", the transcription
 * string is built, the partial transcription is cleared out and both are returned. In this way, the caller can
 * choose to use an "incomplete" transcription when the final one is empty (because of network errors, or audio
 * level being too low, etc)
 *
 * @param messageJson
 * @param transcription
 * @returns {[string,string]}
 */
export const handleEventStreamMessage = (messageJson, transcription) => {
  let results = messageJson.Transcript.Results,
    isFinal = false;

  if (results.length > 0) {
    if (results[0].Alternatives.length > 0) {
      if (results[0].IsPartial) {
        partialTranscription = '';
      } else {
        isFinal = true;
        partialTranscription = transcription;
      }

      let transcript = results[0].Alternatives[0].Transcript;

      // fix encoding for accented characters
      transcript = decodeURIComponent(encodeURIComponent(transcript));

      if (!results[0].IsPartial) {
        transcription += transcript + " ";
      } else {
        partialTranscription += transcript + " "
      }
    }
  }

  return [transcription, partialTranscription, isFinal];
}

// Function for converting blob in buffer
/**
 * Produces a structure consumable in a WebSocket Message Event from the given audioChunk BLOB.
 *
 * @param audioChunk
 * @returns Uint8Array
 */
export const convertAudioToBinaryMessage = (audioChunk) => {
  let raw = MicrophoneStream.toRaw(audioChunk);
  if (raw == null) return null;

  return eventStreamMarshaller.encode(
      getAudioEventMessage(
          Buffer.from(
              pcmEncode(
                  downsampleBuffer(raw, 44100, 44100)
              )
          )
      )
  );
}
